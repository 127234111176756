<template>
  <div>
    <b-sidebar
      id="sidebar-details"
      bg-variant="white"
      v-model="visibility"
      sidebar-class="sidebar-lg"
      right
      no-header
      backdrop
      shadow
    >
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">Details</h5>
        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="visibility = false"
        />
      </div>
      <b-form class="p-2">
        <b-row v-if="currentItem" class="">
          <b-col v-if="currentItem.student" md="12" class="mb-1">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="48"
                  :src="currentItem.student.picture"
                  variant="light-primary"
                />
              </template>

              <b-badge variant="light-primary">
                {{ currentItem.student.name }}
              </b-badge>

              <span
                class="font-weight-bold d-block text-nowrap"
                style="margin: 4px 0px"
              >
                <b-badge variant="light-primary">
                  F/N: {{ currentItem.student.father_name }}
                </b-badge>
              </span>

              <small class="text-muted">
                <b-badge variant="light-primary">
                  GR - {{ currentItem.student.grNo }}
                </b-badge>
                <b-badge variant="light-primary" style="margin-inline: 10px">
                  {{ currentItem.student.meta_class }}
                </b-badge>
              </small>
            </b-media>
          </b-col>
          <b-col v-else-if="currentItem.staff" md="12" class="mb-1">
            <b-media vertical-align="center">
              <template #aside>
                <b-avatar
                  size="48"
                  :src="currentItem.staff.picture"
                  variant="light-primary"
                />
              </template>

              <b-link class="font-weight-bold d-block text-nowrap mb-25">
                <b-badge variant="light-primary">
                  {{ currentItem.staff.name }}
                </b-badge>
              </b-link>
              <small class="text-muted">
                <b-badge variant="light-primary">
                  {{ currentItem.staff.designation }}
                </b-badge>
              </small>
            </b-media>
          </b-col>
          <b-col md="12" class="mt-1">
            <h5 class="font-weight-bolder mb-0">
              Bill# {{ currentItem.order.id }}
            </h5>
            <hr />
            <ul class="list-unstyled">
              <li
                class="d-flex justify-content-between mb-25"
                v-for="(item, ind) in currentItem.details"
                :key="item.id"
              >
                <div class="">
                  <span class="">{{ item.productName }} ({{ item.qty }})</span>
                </div>
                <div class="">Rs.{{ item.total }}</div>
              </li>
            </ul>
            <hr />
            <ul class="list-unstyled">
              <li class="d-flex justify-content-between mb-25">
                <div class="font-weight-bolder">Total</div>
                <div class="font-weight-bolder">
                  Rs.{{ currentItem.order.netTotal }}
                </div>
              </li>
              <li class="d-flex justify-content-between mb-25">
                <div class="font-weight-bolder">Received</div>
                <div class="font-weight-bolder">
                  Rs.{{ currentItem.order.rcvd }}
                </div>
              </li>
              <li class="d-flex justify-content-between mb-25">
                <div class="font-weight-bolder">Balance</div>
                <div class="font-weight-bolder">
                  Rs.{{ currentItem.order.balance }}
                </div>
              </li>
              <hr />
              <div>
                Note:
                <p>{{ currentItem.order.note }}</p>
              </div>
            </ul>
          </b-col>
        </b-row>
      </b-form>

      <sidebar-content />
    </b-sidebar>

    <b-card>
      <b-row class="">
        <b-col class="d-flex align-items-center" md="12">
          <b-form-group class="mb-0 w-100">
            <b-input-group>
              <b-input-group-prepend is-text>
                <feather-icon icon="SearchIcon" />
              </b-input-group-prepend>

              <b-form-input v-model="searchQuery" placeholder="Search...">
              </b-form-input>
            </b-input-group>
          </b-form-group>
        </b-col>
      </b-row>

      <b-table
        class="mt-1"
        ref="selectableTable"
        :items="items"
        :fields="fields"
        :current-page="currentPage"
        :per-page="perPage"
        :busy="dataLoading"
        show-empty
        responsive
        hover
        small
      >
        <template #table-busy>
          <div
            class="d-flex justify-content-center mb-3"
            style="margin-top: 50px"
          >
            <b-spinner
              style="width: 3rem; height: 3rem"
              type="grow"
              variant="primary"
              label="Spinning"
            ></b-spinner>
          </div>
        </template>

        <template #cell(bill)="data">
          <b-badge variant="light-primary">
            {{ data.item.order.id }}
          </b-badge>
        </template>

        <template #cell(details)="data">
          <b-media v-if="data.item.student" vertical-align="center">
            <template #aside>
              <b-avatar
                size="48"
                :src="data.item.student.picture"
                variant="light-primary"
              />
            </template>

            <b-badge variant="light-primary" class="mb-25">
              {{ data.item.student.name }}
            </b-badge>

            <span class="font-weight-bold d-block text-nowrap mb-25">
              <b-badge variant="light-primary">
                F/N: {{ data.item.student.father_name }}
              </b-badge>
            </span>

            <small class="text-muted">
              <b-badge variant="light-primary">
                GR - {{ data.item.student.grNo }}
              </b-badge>
              <b-badge variant="light-primary" class="ml-50">
                {{ data.item.student.meta_class }}
              </b-badge>
            </small>
          </b-media>
          <b-media v-else-if="data.item.staff" vertical-align="center">
            <template #aside>
              <b-avatar
                size="44"
                :src="data.item.staff.picture"
                variant="light-primary"
              />
            </template>

            <b-link class="font-weight-bold d-block text-nowrap mb-25">
              <b-badge variant="light-primary">
                {{ data.item.staff.name }}
              </b-badge>
            </b-link>
            <small class="text-muted">
              <b-badge variant="light-primary">
                {{ data.item.staff.designation }}
              </b-badge>
            </small>
          </b-media>
        </template>

        <template #cell(rcvdDate)="data">
          <b-badge variant="light-primary">
            {{
              new Date(data.item.order.rcvdDate).toLocaleDateString("en-UK", {
                year: "numeric",
                day: "numeric",
                month: "short",
              })
            }}
          </b-badge>
        </template>

        <template #cell(netTotal)="data">
          <b-badge variant="light-primary">
            {{ data.item.order.netTotal }}
          </b-badge>
        </template>
        <template #cell(rcvd)="data">
          <b-badge variant="light-primary">
            {{ data.item.order.rcvd }}
          </b-badge>
        </template>
        <template #cell(balance)="data">
          <b-badge variant="light-primary">
            {{ data.item.order.balance }}
          </b-badge>
        </template>

        <!-- <template #head(actions)="data">
          <div class="ml-2">
            <span>{{ data.label }}</span>
          </div>
        </template> -->

        <template #cell(actions)="data">
          <div class="">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              v-b-tooltip.hover.left
              title="View Details"
              class="btn-icon"
              @click="openDetails(data.item)"
            >
              <feather-icon icon="EyeIcon" class="" />
            </b-button>
            <!-- <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="outline-primary"
              v-b-tooltip.hover.right
              title="Reprint"
              class="btn-icon ml-50"
            >
              <feather-icon icon="PrinterIcon" class="" />
            </b-button> -->
          </div>
        </template>
      </b-table>
      <b-col cols="12" class="d-flex align-items-center justify-content-center">
        <b-pagination
          v-model="currentPage"
          :total-rows="items.length"
          :per-page="perPage"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </b-col>
    </b-card>
  </div>
</template>
<script>
import {
  BCard,
  BCardBody,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BLink,
  BBadge,
  BPagination,
  BImg,
  VBTooltip,
  BFormCheckbox,
  BFormGroup,
  BForm,
  BMediaAside,
  BSpinner,
  BSidebar,
  VBToggle,
  BInputGroupPrepend,
  BFormTextarea,
  BInputGroup,
  BDropdown,
  BDropdownItem,
  BFormSpinbutton,
  BAvatar,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { mapActions, mapGetters } from "vuex";

export default {
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BLink,
    BBadge,
    BPagination,
    BImg,
    VBTooltip,
    BFormCheckbox,
    BFormGroup,
    BForm,
    BMediaAside,
    BSpinner,
    BSidebar,
    VBToggle,
    BInputGroupPrepend,
    BFormTextarea,
    BInputGroup,
    BDropdown,
    BDropdownItem,
    BFormSpinbutton,
    BAvatar,
    vSelect,
    flatPickr,
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-toggle": VBToggle,
    Ripple,
  },
  data() {
    return {
      currentItem: null,
      visibility: false,
      items: [],
      details: [],
      fields: [
        { label: "bill#", key: "bill" },
        { label: "details", key: "details" },
        { label: "date", key: "rcvdDate" },
        { label: "total", key: "netTotal" },
        { label: "received", key: "rcvd" },
        { label: "balance", key: "balance" },
        { key: "actions", label: "actions" },
      ],
      perPage: 30,
      currentPage: 1,
      searchQuery: "",
    };
  },
  created() {
    this.LoadData();
  },
  methods: {
    ...mapActions({ get: "get", post: "post", put: "put", delete: "delete" }),

    async LoadData() {
      this.dataLoading = true;
      var obj = {
        url:
          this.$store.state.domain +
          "posOrders/LoadData?db=" +
          this.$store.state.userData.db +
          "&cID=" +
          this.$store.state.userData.cId,
        token: this.$store.state.userData.token,
      };
      this.items = await this.get(obj);
      this.dataLoading = false;
    },

    openDetails(item) {
      this.currentItem = { ...item };
      this.visibility = true;
    },
  },
};
</script>
<style lang=""></style>
